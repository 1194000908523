<template>
  <v-main>
        <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6" lg="4">
          <v-card class="elevation-12">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Password Creation</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-text-field
                  id="password"
                  label="Password"
                  name="password"
                  prepend-icon="mdi-lock"
                  type="password"
                  v-model="password"
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" :loading="loading" @click="reset"
                >Send</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      password: '',
      loading: false,
      token: this.$route.query.token,
    };
  },
  methods: {
    ...mapActions([
      'resetPassword',
    ]),
    async reset() {
      this.loading = true;
      try {
        await this.resetPassword({
          token: this.token,
          password: this.password,
        });
        this.$router.push('/login');
      } catch (err) {
        console.error(err);
      }
      this.loading = false;
    },
  },
};
</script>
